.wholefilter-wrap {
    margin-top: 30px;
    position: relative;
    .wholefilter-title {
        position: absolute;
        left: 0;
        top: -22px;
    }
    .ant-form-horizontal {
        display: flex;
        flex-wrap: wrap;
        // margin-left: 80px;
        .ant-form-item {
            display: flex;
            width: 24%;

            .ant-form-item-label {
                width: 100px;
            }
        }

        .wholefilter-date {
            width: 50%;
        }

    }
    .wholefilter-btn-a {
        position: absolute;
        right: -2px;
        top: -25px;
        font-size: 12px;
        margin-top: 6px;
        color: #1890ff;
        cursor: pointer
    }

    .wholefilter-btn { 
        margin-top: 15px;
        padding: 30px 0 20px 0;
        position: relative;
        .wholefilter-group {
            position: absolute;
            right: 100px;
            top: -75px;
        }        
        .wholefilter-dashed {
            width: 100%;
            height: 1px;
            border-top: 1px dashed #ccc;
            position: absolute;
            left: 0;
            bottom: 80px;
        }
        >h4 {
            padding-top: 6px;
        }
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .ant-btn {
            margin: 0 15px;
        }
    }
}

.wholefilter-unfold {
    display: none !important;
}
@media screen and (max-width: 1440px){
    .wholefilter-wrap {
        .ant-form-horizontal {
            .ant-form-item {
                width: 33%;
            }
        }
    }
}