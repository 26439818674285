@import '~antd/dist/antd.css';

#root {
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
}

.alert-ant {
  margin-top: 20px;
}

.table-ant {
  margin-top: 10px;
}

.flot {
  position: fixed;
  right: 30px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  z-index: 100;
  .item {
    margin: 8px 0;
    width: 40px;
    height: 40px;
    background: #8D8D8D;
    border-radius: 50%;
    cursor: pointer;
  }

  .qrcode-wrap:hover {
    .qrcode-image {
      transform: translateX(0) scale(1);
      opacity: 1;
      visibility: visible;
    }
  }

  .qrcode-image {
    width: 140px;
    height: 140px;
    position: absolute;
    right: 50px;
    top: -30px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    background: #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    transform: translateX(-40%) scale(1.1);
    transition: all 350ms;
  }
}

.line {
  height: 10px;
  background: #f0f2f5;
  width: calc(100% + 40px);
  margin-left: -20px;
}
.white-line {
  height: 10px;
  background: #fff;
  width: calc(100% + 40px);
  margin-left: -20px;
}

// 在mac系统下，滚动条会自动隐藏，以下样式让其不隐藏
::-webkit-scrollbar{
  width: 6px;
  height: 12px;
}

::-webkit-scrollbar-thumb{
  border-radius: 1em;
  background-color: rgba(50, 50, 50, .2);
}

::-webkit-scrollbar-track{
  border-radius: 1em;
  background-color: rgba(50, 50, 50, .04);
}

//主体样式选择
.mainNameBodyRadio{
  .radio-container{
    margin: 0 10px 10px 0;
    background-color: #fefec6 !important;
    border-bottom: #fe9b27 1px solid;
    border-left: #fe9b27 2px solid;
    border-right: #fe9b27 2px solid;
    border-top: #fe9b27 3px solid;
    min-width: 206px;
    text-align: center;
    line-height: 2em;
    color: #fe9b27 !important;
    border-color: #fe9b27 !important;
    position: relative;
    .check {
      position: absolute;
      right: 0px;
      bottom: 2px;
      z-index: 999;
      color: #fefec6;
      font-weight: bold;
      display: none;
    }
    .ant-radio-button-checked + span {
      .check{
        display: block;
      }
    }
  }
  .ant-radio-button-wrapper-checked::after{
    content: '';
    position: absolute;
    z-index: 98;
    right: 0;
    bottom: 0;
    background-color: #fff;
    width: 0;
    height: 0;
    border-width: 0px 0px 28px 22px;
    border-style: solid;
    border-color: #fe9b27 #fefec6 #fe9b27;
  }
}
.hrefspan{
  cursor: pointer;
  color: #1890ff;
}
.ant-steps-item-description{
  >div{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }
}

.ant-descriptions-row > th, .ant-descriptions-row > td {
  padding-bottom: 6px;
}

.ant-descriptions-item-content{
  word-break: break-all;
}